@import "~antd/dist/antd.css";

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.logo {
  height: 32px;
  border-radius: 6px;
  margin: 16px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.3s;
}

.activeRow {
  background-color: #ebf5fd;
}

.highlight {
  color: #f50;
}

.label {
  font-weight: bold;
}

.topMargin {
  margin-top: 20px;
}

.running {
  color: rgb(14, 204, 14);
  font-weight: bold;
}

.linkbutton {
  background: none !important;
  border: none;
  padding: 0 !important;

  /*optional*/
  font-family: arial, sans-serif; /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.sigPad {
  width: 100%;
  height: 100%;
}
